<template>
  <div>
  <b-table stripe :items="this.$attrs.lines" :fields="fields">
    <template #cell(balance)="row">
      {{row.item.wallet.balance + row.item.wallet.bonus}}
    </template>
    <template #cell(actions)="row">
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template v-slot:button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle mr-25"
          />
        </template>
        <b-dropdown-item  v-on:click="deposit(row.item.id)">
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50 text-success"
          />
          <span class="text-success">Liberar DEPOSITO</span>
        </b-dropdown-item>
        <b-dropdown-item  v-on:click="debit(row.item.id)">
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50 text-danger"
          />
          <span class="text-danger">GERAR SAQUE</span>
        </b-dropdown-item>
        <b-dropdown-item  v-on:click="sendCashback(row.item.id)">
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50"
          />
          <span>Liberar Cashback</span>
        </b-dropdown-item>
        <b-dropdown-item  v-on:click="debitReturn(row.item.id)">
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50 text-yellow"
          />
          <span class="text-yellow">DEVOLVER DINHEIRO</span>
        </b-dropdown-item>
        <b-dropdown-item  v-on:click="creditReturn(row.item.id)">
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50 text-yellow"
          />
          <span class="text-yellow">REMOVER DINHEIRO</span>
        </b-dropdown-item>
        </b-dropdown-item>
        <b-dropdown-item  v-on:click="pushTo(row.item.id)">
          <feather-icon
            icon="EyeIcon"
            class="mr-50 text-primary"
          />
          <span class="text-primary">DETALHE</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
    <b-modal id="deposit-modal" ref="deposit-modal"hide-footer>
      <b-overlay
          id="overlay-background"
          :show="overlay"
          variant="info"
          opacity="0.7"
          blur="1px"
          rounded="lg"
      >
        <template #modal-title>
          Autorizar depósito
        </template>
        <div class="d-block text-center">
          <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Digite o valor a ser creditado"
                label-for="value"
              >
                <b-form-input
                  id="value"
                  v-model="depositValue"
                  name="value"
                  placeholder="Digite o valor a ser creditado"
                  type="number"
                />
              </b-form-group>
              <b-form-radio-group
                id="radio-group-1"
                v-model="withBonus"
                name="radio-options"
                >
                <b-form-radio value="true">Com bonus</b-form-radio>
                <b-form-radio value="false">Sem bonus</b-form-radio>
              </b-form-radio-group>
              <b-button class="mt-3" block @click="sendDepositData()">Enviar Deposito</b-button>
            </b-form>
          </div>
      </b-overlay>
    </b-modal>
    <b-modal id="debit-modal" ref="debit-modal"hide-footer>
      <template #modal-title>
        Autorizar debito em conta
      </template>
      <div class="d-block text-center">
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label="Digite o valor a ser DEBITADO"
              label-for="value"
            >
              <b-form-input
                id="value"
                v-model="depositValue"
                name="value"
                placeholder="Digite o valor a ser DEBITADO"
                type="number"
              />
            </b-form-group>
            <b-button class="mt-3" block @click="sendDebitData()">Enviar Débito</b-button>
          </b-form>
        </div>
    </b-modal>
    <b-modal id="debit-return-modal" ref="debit-return-modal"hide-footer>
      <template #modal-title>
        Autorizar retorno de dinheiro em conta
      </template>
      <div class="d-block text-center">
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label="Digite o valor a ser CREDITADO"
              label-for="value"
            >
              <b-form-input
                id="value"
                v-model="depositValue"
                name="value"
                placeholder="Digite o valor a ser CREDITADO"
                type="number"
              />
            </b-form-group>
            <b-button class="mt-3" block @click="sendDebitReturnData()">Enviar Credito</b-button>
          </b-form>
        </div>
    </b-modal>
    <b-modal id="debit-return-modal" ref="credit-return-modal"hide-footer>
      <template #modal-title>
        Autorizar retirada de dinheiro em conta
      </template>
      <div class="d-block text-center">
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label="Digite o valor a ser DEBITADO"
              label-for="value"
            >
              <b-form-input
                id="value"
                v-model="depositValue"
                name="value"
                placeholder="Digite o valor a ser DEBITADO"
                type="number"
              />
            </b-form-group>
            <b-button class="mt-3" block @click="sendCreditReturnData()">Enviar Debito</b-button>
          </b-form>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {BTable,BOverlay, BDropdown, BDropdownItem, BModal, BFormGroup,BFormRadioGroup,BForm,BButton,BFormInput,BFormRadio} from 'bootstrap-vue'

export default{
  name: 'OneLineDataResult',
  props: {},
  data () {
    return {
      fields: ['id', 'email','profile.name','balance', 'phone', 'invite_code','actions'],
      depositValue:'',
      alterUserId:0,
      withBonus:true,
      overlay:false,
    }
  },
  components: {
    BTable,BOverlay, BDropdown, BDropdownItem,BModal,BFormGroup,BFormRadioGroup,BForm,BButton,BFormInput,BFormRadio
  },
  methods:{
    sendCashback(id){
      console.log(id)
    },
    deposit(id){
      this.alterUserId = id;
      this.$refs['deposit-modal'].show()
      console.log(id)
    },
    pushTo(id){
      // this.$router.push("client-single/"+id)
      // window.location.href = 
      this.$router.push('client-single/' + id);

    },
    debit(id){
      this.alterUserId = id;
      this.$refs['debit-modal'].show()
      console.log(id)
    },
    debitReturn(id){
      this.alterUserId = id;
      this.$refs['debit-return-modal'].show()
    },
    creditReturn(id){
      this.alterUserId = id;
      this.$refs['credit-return-modal'].show()
    },
    sendDebitReturnData(){
      this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/makeDebitReturn',{
        userId: this.alterUserId,
        amount: this.depositValue,
      })
      .then(res => {
          this.$refs['debit-return-modal'].hide()
          this.depositValue = '';
          alert(res.data);
          this.$emit('callReload')
      });
    },
    sendCreditReturnData(){
      this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/makeCreditReturn',{
        userId: this.alterUserId,
        amount: this.depositValue,
      })
      .then(res => {
          this.$refs['credit-return-modal'].hide()
          this.depositValue = '';
          alert(res.data);
          this.$emit('callReload')
      });
    },
    kickUser(id){
      this.$http.post('/player/kick',{
          playerId: id,
        })
        .then(res => {
            console.log(res.data)
        })
   },
    sendDepositData(){
      this.overlay = true;
     this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/makeDeposit',{
        userId: this.alterUserId,
        amount: this.depositValue,
        withBonus: this.withBonus,
      })
      .then(res => {
          this.$refs['deposit-modal'].hide();
          this.overlay = false;
          this.depositValue = '';
          this.withBonus = true;
          alert(res.data);
          this.$emit('callReload');
      });
    },
    sendDebitData(){
     this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/makeDebit',{
        userId: this.alterUserId,
        amount: this.depositValue,
      })
      .then(res => {
          this.$refs['debit-modal'].hide()
          this.depositValue = '';
          alert(res.data)
          this.$emit('callReload')
      })
    }
  },
  created () {
  }
}
</script>

<style scoped>
  .text-yellow{
    color:yellow;
  }
</style>